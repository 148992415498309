import * as dompack from 'dompack';

// general JS (always add)
import '@mod-mijnsiteonline/nodejs/mso';

// main CSS
import './web/css/stylereset.min.css';
import './web/css/rtd.css';
import './nuth.scss';
import 'font-awesome/css/font-awesome.min.css';

// pages
import './pages/agenda/agenda';
import './pages/intranet/intranet';
import './pages/news/news';
import './pages/organizations/organizations';
import './pages/team/team';

// components
import './widgets/widgets';

// template specific JS files
window.$ = require('jquery');
window.jQuery = $;
require('./web/js/jquery.dropotron.min.js');
window.skel = require('./web/js/skel.min.js');
require('./web/js/util.js');
require('./web/js/main.js');

dompack.onDomReady(() => {
  $('#featured .style1 > li > .parent').each(function() {
    let height = $(this).closest('li').height();
    $(this).find('.left').css('height', height);
  });
});
